import React from 'react';

function NotFound404() {
   return (
      <div>
         <h1>404: Page Not Found</h1>
      </div>
   )
}

export default NotFound404;